import React from "react"
import { Link } from "gatsby"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import Background from "../../../assets/images/servicesHeader/nearshore_icon.svg"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import nearshore from "../../../assets/images/servicesPages/nearshore.png"

export default () => (
    <div>
        <SEO title={'Nearshore Software Development Best Solutions for Your Enterprise | ICON Worldwide'} 
        description="Looking for a cutting-edge and on-demand nearshore development solution for your enterprise's tech projects? Discover ICON's solutions now!"
        canonical={'https://icon-worldwide.com/services/nearshore-software-development'}/>
        <Header headerTitle="Nearshore Development" headerSubTitle="A scaleable, on-demand resource for technology."  headerBackground={Background}/>
        <div id="servicesPagesInner">
            <div className="winningTeam">
            <div className="text">
                <h1>Nearshore Software Development: a Cutting-edge Resource for Your Enterprise</h1>
                    <p>ICON Worldwide Bulgaria <span>is the software development branch of ICON WORLDWIDE AG. </span>
                        Our Development Centre in Sofia, Bulgaria <span>provides clients with</span> scalable, on-demand, skilled tech professionals.
                    </p>
                    <h2><Link to="/blog/icon-bulgaria-nearshore-development">Why Bulgaria?</Link></h2>
                </div>
            </div>
            <img className="arrowDown" src={arrow} alt=" ICON Worldwide arrow, nearshore software development" title="ICON nearshore software development, arrow"/>
            <div className="normal-image">
                <img src={nearshore} alt="ICON Worldwide nearshore software development" title="ICON Worldwide nearshore software development"/>
            </div>
            <div className="twoColumns">
                <div className="block-two">
                    <h2><span>Excellence</span>, On Time</h2>
                    <ul>
                        <li>Reduce your development costs by up to 50% </li>
                        <li>Expand your technology capabilities </li> 
                        <li>Improve your on-time delivery</li>
                    </ul>
                    <p>Designed for agencies and brands faced with the scarcity of experienced 
                        developers and the increasing costs of delivering technology products.
                        ICON Worldwide is a leading <Link to="/blog/icon-bulgaria-nearshore-development/">Software Development Outsourcing Company.</Link></p>
                </div>

                <div className="block-two">
                    <h2>Local Strategy and Project Management</h2>
                    <p>Our development teams are led by experienced Technology Leaders. We don’t feel far away,
                        work directly with local based designers and project management in our offices in Zurich,
                        NYC, Madrid, Bahrain or Sofia.</p>
                    <h3 className="black-no-space">On Demand</h3>
                    <p>Ideal for small to mid-size agencies who need top talent on a flexible basis. </p>
                    <h3 className="black-no-space"> Dedicated Teams</h3>
                    <p>Larger agencies can secure a dedicated team.</p>

                </div>
            </div>
            <div className="blackLine"></div>

            <div className="list nearshore-list">
                <h2>How We Work</h2>
                <div className="block left">
                    <h3>Dedicated Teams</h3>
                    <p>Translating the “idea” into reality, as manifested by a name and a visual strategy.
                        We leverage years of experience working with some of the world’s most powerful brands,
                        and applying this experience to creating new brands which are built to last.</p>
                </div>

                <div className="block">
                    <h3>Fixed Price Projects</h3>
                    <p>Bringing your business or product to market in a simple, powerful combination of visual and text
                    able to be extended across all channels of communication. We are happy to participate in an <a href="https://www.designrush.com/agency/it-services/trends/it-services-rfp" target="_blank">rfp for information technology</a></p>
                </div>

                <div className="block right">
                    <h3>Time and Materials</h3>
                    <p>We create living, engaging user experiences across digital the full range of product environments
                        including websites and mobile applications. Design that is intended to be engaged with individually
                        towards specific business objectives.</p>
                </div>
                <div className="yellowLine"></div>
        </div>
        <ServicesBottomNav/>
            </div>
        <Footer noScrollbar="true"/>
    </div>
)